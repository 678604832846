import { PageHeading } from '../../../layout/PageHeading'
import PageSection from '../../../layout/PageSection'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Tab } from '../../../../lib/interfaces'
import { setDocumentTitle } from '../../../../lib/utils'
import { orgSettingsRegex } from '../../../../lib/routes'
import { SettingsTabGeneral } from './settings-tabs/SettingsTabGeneral'
import { SettingsTabApikey } from './settings-tabs/SettingsTabApi'
import { SettingsTabBilling } from './settings-tabs/SettingsTabBilling'
import { Elements } from '@stripe/react-stripe-js'
import { stripeOptions, stripePromise } from '../../../../lib/billing'
import SettingsTabBranding from './settings-tabs/SettingsTabBranding'
import OrgPage from '../../../OrgPage'
import { TabbedMenu } from '../../../layout/TabbedMenu'
import { SettingsTabIntegrations } from './settings-tabs/SettingsTabIntegrations'
import { SettingsTabRevenue } from './settings-tabs/SettingsTabPayouts'

export interface SettingsTabProps {}

export function OrgSettings() {
  // Base tabs change depending on the feature flag.
  const baseTabs: Record<string, Tab> = useMemo(() => {
    const tabs: Record<string, Tab> = {
      '': {
        name: 'General',
        href: '',
        current: false,
      },
      branding: {
        name: 'Branding',
        href: 'branding',
        current: false,
      },
      apikey: {
        name: 'Api Keys',
        href: 'apikey',
        current: false,
      },
      billing: {
        name: 'Billing',
        href: 'billing',
        current: false,
      },
      integrations: {
        name: 'Integrations',
        href: 'integrations',
        current: false,
      },
      payouts: {
        name: 'Payouts',
        href: 'payouts',
        current: false,
      },
    }
    return tabs
  }, [])

  const location = useLocation()
  const { org, theme } = useOrganisationContext()
  const [currentPage, setCurrentPage] = useState<string>('')

  // Always set the document title.
  setDocumentTitle('Settings', 'BRANDED')

  // Extract the tab from the URL and fall back to general if invalid.
  const handlePageLoad = useCallback(
    (pathname: string): void => {
      const re = new RegExp(orgSettingsRegex)
      const matches = re.exec(pathname)
      // If no valid match is found, or the result is empty, default to General.
      let tab = ''
      if (matches && matches.length > 0) {
        tab = matches.pop()?.replace('/', '') || ''
      }
      // If the extracted tab isn't in the available tabs, fall back.
      if (!Object.prototype.hasOwnProperty.call(baseTabs, tab)) {
        tab = ''
      }
      setCurrentPage(tab)
    },
    [baseTabs]
  )

  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [location.pathname, handlePageLoad])

  // Derive the active tabs by marking the one matching currentPage.
  const activeTabs = useMemo(() => {
    const newTabs = { ...baseTabs }
    Object.keys(newTabs).forEach((key) => {
      newTabs[key].current = key === currentPage
    })
    return newTabs
  }, [baseTabs, currentPage])

  return (
    <Fragment>
      {org && (
        <OrgPage
          theme={theme || undefined}
          org={org}
          tabs={[{ name: 'Settings' }]}
        >
          <PageSection>
            <Fragment>
              <PageHeading title="Settings" />
              <TabbedMenu
                tabs={activeTabs}
                currentPage={currentPage}
                org={org}
              />
              {currentPage === '' && <SettingsTabGeneral />}
              {currentPage === 'branding' && <SettingsTabBranding />}
              {currentPage === 'apikey' && <SettingsTabApikey />}
              {currentPage === 'billing' && (
                <Elements stripe={stripePromise} options={stripeOptions}>
                  <SettingsTabBilling />
                </Elements>
              )}
              {currentPage === 'integrations' && <SettingsTabIntegrations />}
              {currentPage === 'payouts' && <SettingsTabRevenue />}
            </Fragment>
          </PageSection>
        </OrgPage>
      )}
    </Fragment>
  )
}
